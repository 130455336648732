/*
 * 업무구분: 상품
 * 화 면 명: MSPFS200M
 * 화면설명: 펀드종합검색7030
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="펀드종합검색" :topButton="true">

    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

     <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left column mr0">
            <div class="row gap40">
              <div class="wrap-input row">
                <label class="mw-auto"> 판매구분 </label>
                <mo-radio v-model="sales_gubun" value="1" class="mr10 flex-unset mw-auto" :disabled="chk_fund_fnm ? true : false"> 당사판매 </mo-radio>
                <mo-radio v-model="sales_gubun" value="2" class="flex-unset mw-auto" :disabled="chk_fund_fnm ? true : false"> 시장판매 </mo-radio>
              </div>
              <div class="w116">
                <div class="wrap-input row" v-if="sales_gubun == '1'">
                  <mo-radio v-model="irp_gubun" value="1" class="mr10 flex-unset mw-auto" :disabled="chk_fund_fnm ? true : false"> 일반 </mo-radio>
                  <mo-radio v-model="irp_gubun" value="2" class="flex-unset mw-auto" :disabled="chk_fund_fnm ? true : false"> 퇴직 </mo-radio>
                </div>
              </div>
              <div class="wrap-input row" v-if="sales_gubun == '2'">
                <label class="mw-auto"> 대표구분 </label>
                <mo-radio v-model="repr_gubun" value="1" class="mr10 flex-unset mw-auto" :disabled="chk_fund_fnm ? true : false"> 대표펀드 </mo-radio>
                <mo-radio v-model="repr_gubun" value="2" class="flex-unset mw-auto" :disabled="chk_fund_fnm ? true : false"> 전체 </mo-radio>
              </div>
            </div>

            <div class="row gap40">
              <div class="wrap-input row">
                <label> 제로인펀드유형 </label>
                <mo-dropdown :items="zeroin_type_list" v-model="zeroin_type_lcd" :disabled="chk_fund_fnm ? true : false" class="w279" />
              </div>
              <div class="wrap-input row">
                <label class="mw-auto"> 운용회사 </label>
                <mo-dropdown :items="lv_commCode.code991" v-model="unyong_cd" :disabled="chk_fund_fnm ? true : false" class="w410" />
              </div>
            </div>

            <div class="row gap40">
              <div class="wrap-input row">
                <label class="mw-auto"> 펀드규모 </label>
                <mo-dropdown :items="Combo_seolj_aek" v-model="seolj_aek" :disabled="chk_fund_fnm ? true : false" class="w163" />
                <mo-dropdown :items="Combo_seoj_updown" v-model="seoj_updown" :disabled="chk_fund_fnm ? true : false" class="w146" />
              </div>
              <div class="wrap-input row">
                <label class="mw-auto"> 조건 </label>
                <mo-dropdown :items="Combo_cond_gubun" v-model="cond_gubun" :disabled="chk_fund_fnm ? true : false" class="w116 flex-unset" />
                <mo-dropdown :items="Combo_cond_updown" v-model="cond_updown"  :disabled="chk_fund_fnm ? true : false" class="w100 flex-unset" />
                <mo-text-field v-model="rate" class="w90" ref="inputRate" maxlength="6" @keyup="fn_keyupRate($event)" :disabled="chk_fund_fnm ? true : false" />
                <mo-dropdown :items="Combo_pers_updown" v-model="pers_updown" :disabled="chk_fund_fnm ? true : false" class="w100 flex-unset" />
              </div>
            </div>

            <div class="row gap40">
              <div class="wrap-input row">
                <mo-checkbox v-model="chk_fund_fnm" class="flex-unset mw-auto"> 펀드명검색 </mo-checkbox>
                <mo-text-field v-model="fund_fnm" class="w279" :disabled="chk_fund_fnm ? false : true" />
              </div>
              <div class="wrap-input row">
                <label class="mw-auto"> 분석기간 </label>
                <mo-dropdown :items="Combo_term" v-model="term" class="w243" />
              </div>
              <div class="wrap-button row ml2">
                <mo-button class="clear" @click="fn_init"> 초기화 </mo-button>
                <mo-button primary class="inquiry" @click="fn_searchList"> 조회 </mo-button>
              </div>
            </div>

          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="row gap40 mt22">
          <span class="desc02">기준가: {{gijun_ga_ymd}}</span>
          <span class="desc02">변동성: {{yy_dev_ymd}}</span>
        </div>
        <div class="wrap-table mt5 h-scroll">
          <table class="table row-type td-lh1-4">
            <thead>
              <tr>
                <th rowspan="2" class="w260">펀드명</th>
                <th rowspan="2" class="w40">당사<br/>판매</th>
                <th rowspan="2">운용회사</th>
                <th rowspan="2">펀드규모<br/>(억원)</th>
                <th rowspan="2">펀드유형</th>
                <th rowspan="2">기준가</th>
                <th colspan="2">
                  <span v-if="term != '12'">{{term}}개월</span>
                  <span v-else>1년</span>
                </th>
              </tr>
              <tr>
                <th class="border-left">수익률</th>
                <th>변동성</th>
              </tr>
            </thead>
            <tbody  v-if="!(ds_list == null)">
              <tr v-for="(row, i) in ds_list" :key="i">
                <td class="aL"> {{row.fund_fnm}} </td>
                <td> {{row.bfcer_fnd_clct_sc_cd}} </td>
                <td> {{row.unyong_fnm}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(row.seolj_aek)}} </td>
                <td> {{row.zeroin_type_lnm}} </td>
                <td class="aR"> {{row.gijun_ga}} </td>
                <td class="aR"> {{row.silh_suik_rt}} </td>
                <td class="aR"> {{row.yy_dev}} </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Pagination -->
        <!-- <div class="wrap-pagination">
          <mo-pagination :pagination="page" @move="noti" />
        </div> -->
        <!-- //Pagination -->

      </ur-box-container>

    </main>
    <!-- //.fts-main -->
    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
***********************************************************************************/
import FSAlertPopup from '@/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPFS200M',
  screenId: 'MSPFS200M',
  components: {
    'fs-alert-popup': FSAlertPopup,
    'fs-header': FSHeader
  },
  /***********************************************************************************
     * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    // 공통코드 셋팅
    this.fn_SetCommCode()
  },
  mounted () {
    this.$bizUtil.insSrnLog('MSPFS200M')
    // 초기화
    this.fn_init()
  },
  /***********************************************************************************
     * 화면변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      // 공통 객체
      pHeaderObj: {
        title: '펀드종합검색',
        step: ''
      },
      // ds_search
      // ds_search = {
      //   zeroin_type_lcd : '',
      //   seolj_aek : '',
      //   seoj_updown : '',
      //   fund_fnm : '',
      //   unyong_cd : '',
      //   cond_updown  : '',
      //   silh_suik_rt : '',
      //   yy_dev : '',
      //   term : '',
      //   repr_gubun : '',
      //   cond_gubun : '',
      //   pers_updown : '',
      //   chk_fund_fnm : '',
      //   pagenum : '',
      //   rate : '',
      //   sales_gubun : '',
      //   irp_gubun : '',
      //   fund_cd : '',
      // };
      sales_gubun: '1', // rdo_sale_gubun 판매구분1
      irp_gubun: '1', // rdo_irp_gubun 판매구분2
      repr_gubun: '1', // rdo_repr_gubun 대표구분
      zeroin_type_lcd: '', // Combo_zeroin_type 제로인펀드유형
      seolj_aek: '5000000000', // Combo_seolj_aek 펀드규모1
      seoj_updown: '1', // Combo_seoj_updown 펀드규모2
      fund_fnm: '', // edt_fund_fnm 펀드명
      unyong_cd: '', // Combo_unyong 운용회사
      cond_gubun: '1', // Combo_cond_gubun 조건1
      cond_updown: '1', // Combo_cond_updown 조건2
      rate: '10%', // edt_persent '%' -> replace ''

      pers_updown: '1', // Combo_pers_updown 조건3
      term: '', // Combo_term 분석기간

      chk_fund_fnm: false, // Check_fund_fnm 펀드명검색 checkbox
      pagenum: 0,

      gijun_ga_ymd: '', // 기준가 날짜
      yy_dev_ymd: '', // 변동성 날짜

      // 조회결과
      ds_list: [],

      // disabled
      fndNmDis: true, // 펀드명

      pAlertPopupObj: {}, // alert
      lv_commCode: {}, // 공통코드
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj()

    }
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    // 제로인펀드유형
    zeroin_type_list () {
      let rtn = []
      rtn.push({value: '', text: '전체'})
      rtn.push({value: '10', text: '채권형'})
      rtn.push({value: '11', text: 'MMF'})
      rtn.push({value: '20', text: '주식형'})
      rtn.push({value: '21', text: '주식혼합형'})
      rtn.push({value: '22', text: '채권혼합형'})
      rtn.push({value: '23', text: '절대수익추구형'})
      rtn.push({value: '24', text: '부동산형'})
      rtn.push({value: '28', text: '특별자산'})
      rtn.push({value: '29', text: '기타'})
      rtn.push({value: '50', text: '해외채권형'})
      rtn.push({value: '60', text: '해외주식형'})
      rtn.push({value: '61', text: '해외주식혼합형'})
      rtn.push({value: '62', text: '해외채권혼합형'})
      rtn.push({value: '63', text: '해외멀티에셋형'})
      rtn.push({value: '64', text: '해외부동산형'})
      rtn.push({value: '65', text: '커머더티형'})
      rtn.push({value: '68', text: '해외특별자산'})
      rtn.push({value: '69', text: '해외기타'})
      return rtn
    },
    // 펀드규모1
    Combo_seolj_aek () {
      let rtn = []
      rtn.push({value: '5000000000', text: '50억'})
      rtn.push({value: '10000000000', text: '100억'})
      rtn.push({value: '50000000000', text: '500억'})
      rtn.push({value: '100000000000', text: '1,000억'})
      return rtn
    },
    // 펀드규모2
    Combo_seoj_updown () {
      let rtn = []
      rtn.push({value: '1', text: '이상'})
      rtn.push({value: '2', text: '이하'})
      return rtn
    },
    // 조건1
    Combo_cond_gubun () {
      let rtn = []
      rtn.push({value: '1', text: '수익률'})
      rtn.push({value: '2', text: '변동성'})
      return rtn
    },
    // 조건2
    Combo_cond_updown () {
      let rtn = []
      rtn.push({value: '1', text: '상위'})
      rtn.push({value: '2', text: '하위'})
      return rtn
    },
    // 조건4
    Combo_pers_updown () {
      let rtn = []
      rtn.push({value: '1', text: '이상'})
      rtn.push({value: '2', text: '이하'})
      return rtn
    },
    // 분석기간
    Combo_term () {
      let rtn = []
      rtn.push({value: '1', text: '1개월'})
      rtn.push({value: '3', text: '3개월'})
      rtn.push({value: '6', text: '6개월'})
      rtn.push({value: '9', text: '9개월'})
      rtn.push({value: '12', text: '1년'})
      return rtn
    }
  },
  /***********************************************************************************
     * watch 함수 정의 영역
  ***********************************************************************************/
  watch: {

  },
  /***********************************************************************************
     * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /***************************************************************************************
       초기화
    *****************************************************************************************/
    fn_init () {
      console.log('fn_init 초기화함수')
      this.cond_gubun = '1' // 구분값 디폴트
      this.cond_updown = '1'
      this.pers_updown = '1'
      this.seoj_updown = '1'
      this.seolj_aek = '5000000000'
      this.term = '1'
      this.unyong_cd = ''
      this.zeroin_type_lcd = ''
      this.irp_gubun = '1'
      this.repr_gubun = '1'
      this.sales_gubun = '1'
      this.chk_fund_fnm = false
      this.fund_fnm = ''
      this.rate = '10%'
      this.fndNmDis = true

      this.ijun_ga_ymd = '' // 기준가 날짜
      this.yy_dev_ymd = '' // 변동성 날짜

      // 조회결과
      this.ds_list = []
    },
    /***************************************************************************************
       펀드목록조회
    *****************************************************************************************/
    fn_searchList () {
      console.log('fn_searchList')
      this.ds_list = []
      // this.ds_search = {};
      // this.ds_search.rate = this.rate.replace("%", ""); //

      var eaiId = ''
      if (this.sales_gubun === '1' && this.irp_gubun === '1') {
        // "selectCS703000_List"; //당사, 일반
        eaiId = 'C391_F10570196_S'
      } else if (this.sales_gubun === '1' && this.irp_gubun === '2') {
        // "selectCS703000_irpList"; //당사, 퇴직
        eaiId = 'C391_F10570168_S'
      } else if (this.sales_gubun === '2') {
        // "selectCS703000_zeroinList"; //시장
        eaiId = 'C391_F10570169_S'
      } else {
        console.log('트랜없음')
        return
      }

      console.log('eaiId --->', eaiId)
      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = eaiId
      this.eaiCommObj.params = {
        chk_fund_fnm: this.chk_fund_fnm, // 펀드명 검색 체크박스
        cond_gubun: this.cond_gubun, // 조건1
        cond_updown: this.cond_updown, // 조건2
        fund_fnm: this.fund_fnm, // 펀드명
        irp_gubun: this.irp_gubun, // 퇴직*일반 선택값
        pers_updown: this.pers_updown, // 조건4
        rate: this.rate.replace('%', ''),
        repr_gubun: this.repr_gubun,
        sales_gubun: this.sales_gubun,
        seoj_updown: this.seoj_updown,
        seolj_aek: this.seolj_aek,
        // silh_suik_rt : 0,
        term: this.term,
        unyong_cd: this.unyong_cd,
        // yy_dev : 0,
        zeroin_type_lcd: this.zeroin_type_lcd
        // pagenum : 0,
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    /***************************************************************************************
       CALLBACK
    *****************************************************************************************/
    fn_callBack (response) {
      console.log('콜백response ====', response)
      let t_list = []
      if (this.eaiCommObj.commHeaderVO.eaiId === 'C391_F10570196_S') { // 당사, 일반
        t_list = response.data.cs703000_O_00VO
      } else if (this.eaiCommObj.commHeaderVO.eaiId === 'C391_F10570168_S') { // 당사, 퇴직
        t_list = response.data.cs703000_O_02VO
      } else if (this.eaiCommObj.commHeaderVO.eaiId === 'C391_F10570169_S') { // 시장
        t_list = response.data.cs703000_O_01VO
      }

      t_list.forEach(item => {
        item.seolj_aek = Math.round(item.seolj_aek)
      })

      this.ds_list = t_list

      if ( FSCommUtil.gfn_length(this.ds_list) > 0 ) {
        var ga_ymd = this.ds_list[0].gijun_ymd
        var dev_ymd = this.ds_list[0].week_gijun_ymd

        this.gijun_ga_ymd = FSCommUtil.gfn_dateReplace(1, ga_ymd) // ga_ymd.substr(0, 4) + "-" + ga_ymd.substr(4, 2) + "-" + ga_ymd.substr(6, 2);
        this.yy_dev_ymd = FSCommUtil.gfn_dateReplace(1, dev_ymd) // dev_ymd.substr(0, 4) + "-" + dev_ymd.substr(4, 2) + "-" + dev_ymd.substr(6, 2);

        // this.ds_list.appendData(this.ds_temp, false, false);
        console.log('rowcount = ' + FSCommUtil.gfn_length(this.ds_list))
      } else {
        let t_popupObj = {content: '조회할 데이터가 없습니다.'}
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
    ******************************************************************************/
    fn_SetCommCode () {
      console.log('fn_SetCommCode...')

      let params = [
        {'cId': '991', 'dayChkYn': 'N'}
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult (pResult) {
      console.log('공통코드991 result', pResult)
      this.lv_commCode = pResult

      let t_codeList = []

      pResult.code991.forEach((item, idx) => {
        if (idx === 0) {
          t_codeList.push({
            text: '전체',
            value: ''
          })
          t_codeList.push(item)
        } else {
          t_codeList.push(item)
        }
      })

      this.lv_commCode.code991 = t_codeList
    },

    /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
    ******************************************************************************/
    fn_AlertPopup (type, pPopupObj) {
      if ( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        this.pAlertPopupObj.content = pPopupObj.content
        this.pAlertPopupObj.confirm = false
        this.pAlertPopupObj.cancel = true

        if ( !pPopupObj.cancel ) {
          this.pAlertPopupObj.cancel = pPopupObj.cancel
        }
        if ( pPopupObj.confirm ) {
          this.pAlertPopupObj.confirm = pPopupObj.confirm
          this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
          this.pAlertPopupObj.confirmData = pPopupObj.confirmData
        }
        if ( pPopupObj.closeFunc ) {
          this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
          this.pAlertPopupObj.closeData = pPopupObj.closeData
        }
      }
      this.$refs.alertPopup.fn_Open()
    },
    /******************************************************************************
       * Function명 : fn_keyupRate
       * 설명       : 조건 keyup 이벤트
    ******************************************************************************/
    fn_keyupRate (e) {
      this.rate = e.target.value.replace(/[^0-9]/g, '')

      if (this.rate === '') {
        this.rate = 0
      }

      this.rate = parseInt(this.rate) + '%'

      let inputRate = this.$refs.inputRate.$el.querySelector('input')

      this.$nextTick(() => {
        inputRate.setSelectionRange(this.rate.length - 1, this.rate.length - 1)
      })
    }
  }
}
</script>
<style scoped>
</style>
